import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"

const NotFoundPage = () => (
  <Layout showTitle={true} slug="/">
    <SEO title="404: Not found" />
    <div style={{textAlign: "center"}}>
      <img src="/Kawaii404NotFoundBySawaratsuki.png" alt="404 Not Found"
        style= {{
          width:"50%",
          height: "50%",
        }}/>
      <p style={{ textAlign: "right" }}>
            <a href="https://github.com/SAWARATSUKI/KawaiiLogos" target="_blank" rel="noopener noreferrer">KawaiiLogo</a> by <a href="https://twitter.com/sawaratsuki1004" target="_blank" rel="noopener noreferrer">さわらつき</a>
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
